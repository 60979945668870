<template>
  <v-container fluid grid-list style="padding: 20px">
    <div v-if="matchingPreBookings.length > 0">
      <v-layout row style="margin-bottom: 20px">
        <v-flex xs1>
          <h2>ID</h2>
        </v-flex>
        <v-flex xs3>
          <h2 style="padding-left: 20px">Available</h2>
        </v-flex>
        <v-flex xs2>
          <h2 style="padding-left: 10px">Vehicles</h2>
        </v-flex>
        <v-flex xs2>
          <h2 style="padding-left: 10px">Drivers</h2>
        </v-flex>
        <v-flex xs2>
          <h2>Takehome</h2>
        </v-flex>
        <v-flex xs2></v-flex>
      </v-layout>

      <v-layout
        v-for="(prebooking, prebookingIndex) of matchingPreBookings"
        :key="`link-prebooking-row-${prebookingIndex}`"
      >
        <v-flex xs1>
          <!-- <p style="margin-bottom: 0;">Pre-Booking Available</p> -->
          <p style="text-align: left; margin-top: 10px">
            {{ prebooking.parentReservationManagedId }}
          </p>
        </v-flex>
        <v-flex xs3 style="padding: 0 10px">
          <!-- <p style="margin-bottom: 0;">Pre-Booking Available</p> -->
          <p style="text-align: center; margin-top: 10px">
            {{ formattedDates(prebooking) }}
          </p>
        </v-flex>
        <v-flex xs2>
          <v-text-field
            :id="1"
            :value="prebooking.requiredVehicles.length"
            readonly
            flat
            solo
            class="ml-2 mr-2"
            type="number"
            style="width: 100px"
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            :id="2"
            :value="prebooking.requiredDrivers"
            readonly
            flat
            solo
            class="ml-2 mr-2"
            type="number"
            style="width: 100px"
          />
        </v-flex>
        <v-flex xs2>
          <v-text-field
            :id="3"
            :value="prebooking.amount"
            readonly
            flat
            solo
            class="ml-2 mr-2"
            type="number"
            style="width: 100px"
          />
        </v-flex>
        <v-flex xs2>
          <v-btn
            color="primary"
            style="margin-top: 2px"
            @click="handleLink(prebooking)"
          >
            Link
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
    <h3 v-else style="text-align: center; margin-top: 20px">
      No pre-bookings found
    </h3>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '@/utils/env'
import { EventBus } from '@/utils/event-bus'
import { DateTime } from 'luxon'

export default {
  props: {
    companyId: {
      type: Number,
      default: null,
    },
    reservationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      matchingPreBookings: [],
    }
  },
  async mounted() {
    const url = `https://${baseUrl()}/prebooking/${this.companyId}/${
      this.reservationId
    }/find`
    const { data } = await axios.get(url)
    this.matchingPreBookings = data.matchingPreBookings
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    handleLink(prebooking) {
      EventBus.$emit('link-prebooking-row', this.reservationId, prebooking)
      this.close()
    },
    formattedDates(prebooking) {
      return `${DateTime.fromISO(prebooking.startDate).toFormat(
        'MM/dd/yy'
      )} - ${DateTime.fromISO(prebooking.endDate).toFormat('MM/dd/yy')}`
    },
  },
}
</script>
